<template>
  <responsive-layout>
    <v-sheet light class="transparent">
      <div class="main py-8">
        <v-row
          justify="center"
          align="center"
          class="mx-4 mb-8 mb-lg-12"
          style="padding-top: 6.25rem"
        >
          <v-col cols="12" class="d-flex flex-column align-center">
            <v-img
              class="mx-auto"
              src="https://vod.eventcdn.net/pandora/smc2021/Logo%402x.png"
              style="width: 385px; max-width: 100%"
            ></v-img>
          </v-col>
          <!--<lh-countdown<v-col cols="12" style="max-width: 580px">
            <div class="mx-auto">
              <p style="font-size: 1.1rem" class="white--text">STARTING IN:</p>
              <br />
             
                :time="time"
                value-class="countdown-value"
                content-class="white--text mt-n10"
                :labels="{
                  days: 'Days',
                  hours: 'Hours',
                  minutes: 'Minutes',
                  seconds: 'Seconds',
                }"
              ></lh-countdown>
            </div>
          </v-col>-->
        </v-row>

        <v-row
          align="center"
          justify="center"
          v-if="errors.alreadyRegistered || showThanks"
        >
          <v-col cols="12" class="pa-0" style="max-width: 580px">
            <v-card
              class="black white--text"
              tile
              style="border: none"
              elevation="0"
            >
              <v-card-text class="white--text pa-8">
                <!-- Already registered -->
                <template v-if="errors.alreadyRegistered">
                  You have already registered to Pandora SMC 2021.
                  <br />
                  <br />
                  Please check your inbox.
                </template>
                <!-- Thank you message -->
                <template v-if="showThanks">
                  <h2 style="font-size: 1.5rem">
                    {{ user.name }}
                  </h2>
                  <br />
                  <div class="px-4 px-lg-16">
                    <p>
                      Thank you for registering!
                      <br />
                      <br />
                      Pandorians at every level – store, field, and corporate
                      leaders – are coming together for the 2021 PANDORA DARES
                      Conference! Inclusive of blended learning experience
                      filled with live facilitated sessions, interactive
                      learning, and curated resources, this conference will be
                      an experience like no other! Stay tuned for details on
                      conference platform access, session options, and
                      everything you need to know to make this a successful
                      learning journey.
                    </p>
                  </div>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- Form -->
        <v-row v-else align="center" justify="center" class="mx-4">
          <v-col cols="12" class="lighter-grey pa-0" style="max-width: 580px">
            <div
              class="pl-2 py-3 black text-left white--text"
              style="font-size: 1.2rem"
            >
              <h3 class="pa-4">Register for the conference</h3>
            </div>
            <!-- Form -->
            <div
              v-if="!showThanks && !errors.alreadyRegistered"
              class="px-4 py-6"
            >
              <validation-observer #default="{ invalid }">
                <v-form @submit.prevent="register">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="name"
                          rules="required"
                        >
                          <v-text-field
                            outlined
                            hide-details="auto"
                            v-model="form.name"
                            label="Name *"
                            placeholder="Enter your full name"
                            persistent-placeholder
                            required
                            :error-messages="errors"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="email address"
                          rules="required|email"
                        >
                          <v-text-field
                            outlined
                            hide-details="auto"
                            v-model="form.email"
                            label="Email Address *"
                            placeholder="Enter your Email Address"
                            persistent-placeholder
                            required
                            :error-messages="errors"
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="store name"
                          rules="required"
                        >
                          <v-autocomplete
                            clearable
                            outlined
                            hide-details="auto"
                            v-model="form.store_name"
                            label="Store Name *"
                            placeholder="Search for your Store Name"
                            persistent-placeholder
                            :items="options.store_names"
                            required
                            :error-messages="errors"
                          ></v-autocomplete>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="territory manager"
                          rules="required"
                        >
                          <v-autocomplete
                            clearable
                            outlined
                            hide-details="auto"
                            v-model="form.territory_manager"
                            label="Territory Manager *"
                            placeholder="Search for your Territory Manager"
                            persistent-placeholder
                            :items="options.territory_managers"
                            required
                            :error-messages="errors"
                          ></v-autocomplete>
                        </validation-provider>
                      </v-col>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="registration type"
                          rules="required"
                        >
                          <v-autocomplete
                            clearable
                            outlined
                            v-model="form.registration_type"
                            hide-details="auto"
                            label="Registration Type *"
                            placeholder="Search for your Registration Type"
                            persistent-placeholder
                            required
                            :error-messages="errors"
                            :items="options.registration_types"
                          >
                          </v-autocomplete>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          #default="{ errors }"
                          name="livehouse terms & conditions"
                          rules="required"
                        >
                          <v-checkbox
                            v-model="form.livehouse_terms"
                            required
                            :false-value="null"
                            :error-messages="errors"
                          >
                            <template #label>
                              <div>
                                I agree to
                                <a
                                  class="black--text"
                                  href="https://livehouse.com/terms/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style="font-size: 1rem"
                                  @click.stop
                                  >Livehouse Terms & Conditions
                                </a>
                              </div>
                            </template>
                          </v-checkbox>
                        </validation-provider>
                      </v-col>
                      <!--<v-col cols="12" class="pt-0 mt-n8">
                      <validation-provider
                        #default="{ errors }"
                        name="pandora terms & conditions"
                        rules="required"
                      >
                        <v-checkbox
                          v-model="form.pandora_terms"
                          required
                          :error-messages="errors"
                        >
                          <template #label>
                            <div>
                              I agree to
                              <a
                                class="black--text"
                                href=""
                                target="_blank"
                                rel="noopener noreferrer"
                                style="font-size: 1rem"
                                @click.stop
                              >
                                Pandora Terms & Conditions</a
                              >
                            </div>
                          </template>
                        </v-checkbox>
                      </validation-provider>
                    </v-col>-->
                    </v-row>

                    <v-row justify="start" class="px-4">
                      <v-btn
                        color="pink"
                        class="mr-4 black--text font-weight-bold"
                        type="submit"
                        :disabled="!isValid"
                        depressed
                      >
                        Register
                      </v-btn>
                    </v-row>
                  </v-container>
                </v-form>
              </validation-observer>
            </div>
            <!-- End form -->
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </responsive-layout>
</template>

<script>
import axios from "axios";

// Data
import STORE_NAMES from "@/assets/data/storenames";
import TERRITORY_MANAGERS from "@/assets/data/territorymanagers";

// Components
import ResponsiveLayout from "@/layouts/ResponsiveLayout.vue";

import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
export default {
  name: "app-register",

  components: {
    ValidationProvider,
    ValidationObserver,
    ResponsiveLayout,
  },

  data() {
    const REGISTRATION_TYPES = [
      "Franchise Store Manager",
      "O&O Store Manager",
      "Franchise Owner",
      "District Manager",
      "Regional Sales Manager",
      "Franchise Key Decision Manager",
      "Multibrand Owner",
      "Baltimore HQ",
      "Columbia LC",
      "Vendor",
      "Territory Manager",
      "Key Account Manager",
      "N/A",
    ];

    return {
      errors: {
        alreadyRegistered: false,
      },
      showThanks: false,
      time: "20210914T150000Z",
      options: {
        store_names: STORE_NAMES.map((store_name) => {
          return {
            text: store_name,
            value: store_name,
          };
        }),
        territory_managers: TERRITORY_MANAGERS.map((tm) => {
          return {
            text: tm,
            value: tm,
          };
        }),
        registration_types: REGISTRATION_TYPES.map((registration_type) => {
          return {
            text: registration_type,
            value: registration_type,
          };
        }),
      },
      user: {
        name: null,
      },
      form: {
        name: null,
        email: null,
        store_name: null,
        territory_manager: null,
        registration_type: null,
        livehouse_terms: null,
        //pandora_terms: null,
      },
    };
  },

  computed: {
    isValid() {
      return Object.values(this.form).every((field) => !!field);
    },
  },

  methods: {
    async register() {
      console.log("Data:", this.form);

      const data = {
        ...this.form,
      };

      let response = null;
      try {
        response = await axios.post(
          `${process.env.VUE_APP_API_URL}register/`,
          data
        );
      } catch (exc) {
        if (exc?.response?.status === 400) {
          console.log(
            "Error",
            exc?.response?.data,
            "Status:",
            exc?.response?.status
          );
          this.errors.alreadyRegistered = true;
        }
      }

      if (response && response?.status == 200) {
        console.log(
          "Response status:",
          response?.status,
          "Data:",
          response?.data
        );
        this.showThanks = true;
        this.user.name = data.name;

        this.form = {
          name: null,
          email: null,
          store_name: null,
          territory_manager: null,
          registration_type: null,
          livehouse_terms: null,
          pandora_terms: null,
        };
      } else {
        console.log("Error", response?.data);
        this.errors.alreadyRegistered = true;
      }
    },
  },
};
</script>

<style scoped>
.main {
  background-image: url("https://vod.eventcdn.net/pandora/smc2021/Registration_Bg%402x.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /*height: 1080px;*/
  min-height: 100vh;
  backface-visibility: hidden;
}
/*
Needs to add padding to inner field
.v-input:not(.v-input--checkbox) {
  border: none !important;
  padding: 10px !important;
} */
</style>
